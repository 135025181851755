import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;

import objectFitImages from 'object-fit-images';

require('./lib/featherlight.min.js');
require('./lib/owl.carousel.js');

$(document).ready(function () {
  objectFitImages();

  $('.nav-menu a').each(function () {
    $(this).on('click', function () {
      console.log('clicked');
    })
  });
});

(function () {
  'use strict';

  var fullWidthSlider = $('.owl-carousel.full-width');
  var carousel = $('.owl-carousel.carousel');

  //initiate mobile navigation
  $('.menu-toggle').on('click tap touch', function () {
    var _this = $(this);
    var main_nav = $('.main-navigation nav');
    _this.toggleClass('nav-open');
    main_nav.toggleClass('open');
  });

  //detect parent nav item
  $('.nav-menu > li').each(function () {
    // console.log('test');
    var _this = $(this);
    if (_this.find('ul').length > 0) {
      _this.addClass('has-children');
    }
  });

  //careers listing form submission trigger
  $('.select-go').on('change', function (e) {
    if ($(this).val()) {
      window.location = $(this).val();
    }
  });

  //initiate carousel
  if (carousel.length > 0) {
    carousel.owlCarousel({
      loop: true,
      center: false,
      items: 1,
      singleItem: true,
      margin: 45,
      nav: false,
      dots: false,
      responsive: {
        767: {
          items: 2,
        },
        992: {
          items: 3,
        },
      },
      onInitialized: (event) => {

        //count vars
        let slider = $(event.currentTarget).parent().find('.owl-carousel');
        let slide_count = slider.find('.owl-item').not('.cloned').length;
        slider.parent().find('.total').text(slide_count);

        //custom navigation
        $(document).on('click', '.oc-nav-item', function () {
          var _this = $(this);
          var parentSlider = _this.parents().eq(1).find('.owl-carousel');
          var direction = (_this.hasClass('custom-next')) ? 'next' : 'prev';
          customOwlNav(parentSlider, direction);
        });

      },
      onChanged: (event) => {
        //owl carousel bug returns wrong index on change, this is a fix for that
        let slider = $(event.currentTarget).parent().find('.owl-carousel');
        var currentItem = (event.item.index + 1) - event.relatedTarget._clones.length / 2;
        var allItems = event.item.count;
        if (currentItem > allItems || currentItem == 0) {
          currentItem = allItems - (currentItem % allItems);
        }
        slider.parent().find('.current').text(currentItem);
      },
    });

  }

  //initiate full width slider
  if (fullWidthSlider.length > 0) {
    fullWidthSlider.owlCarousel({
      loop: true,
      center: true,
      singleItem: true,
      margin: 0,
      nav: false,
      dots: false,
      responsive: {
        0: {
          items: 1,
          loop: true,
        },
      },
      onInitialized: (event) => {

        //count vars
        let slider = $(event.currentTarget);
        let slide_count = slider.find('.owl-item').not('.cloned').length;
        slider.find('.total').text(slide_count);

        //custom navigation
        $(document).on('click', '.oc-nav-item', function () {
          var _this = $(this);
          var parentSlider = _this.parents().eq(3);
          var direction = (_this.hasClass('custom-next')) ? 'next' : 'prev';
          customOwlNav(parentSlider, direction);
        });

      },
      onChanged: (event) => {
        let currentItem = event.item.index - 1;
      },
    });
  }

  function customOwlNav(parentSlider, direction) {
    let slideDirection = direction + '.owl.carousel';
    parentSlider.trigger(slideDirection)
  }


})();
